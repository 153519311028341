<template>
  <div class="grey-background">
    <div v-if="isCameraOpen" v-show="!isLoading" :class="{ 'flash' : isShotPhoto }" align="center">
      <br>
      <p v-if="isCameraOpen && !isLoading && !isPhotoTaken"  class="paragraph">{{$t('PhotoIdCard')}}</p>
      <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
      <video v-show="!isPhotoTaken" ref="camera" class="camera-stream" :width="250" :main-height="120" autoplay>
      </video>
    </div>
    <v-container v-if="isCameraOpen && !isLoading && !isPhotoTaken" class="camera-shoot" style="width:100%" align="center">
      <v-row justify="center">
        <v-col cols="auto">
          <v-img 
            class="camera-img-capture"
            max-width="50px"
            max-height="50px"  
            :src="require('../../assets/kyc/buttonCamera.svg')"
            @click="takePhoto()">
          </v-img>
        </v-col>
        <v-col cols="auto mt-n5" style="right: 5%;position: absolute;">
          <v-img
            class="camera-img-split mt-2"
            max-width="30px"
            max-height="30px"  
            :src="require('../../assets/kyc/camera-back.svg')"
            @click="changeCamera()">
          </v-img>
        </v-col>
      </v-row>
    </v-container>

    <div v-show="isPhotoTaken" class="white-background">
      <notification-popup 
      :dialog="notifFailed"
      :contentMsg="$t('failed')"
      :headerMsg="$t('FailedSubmit')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('labelYes')"
      colorIcon = "danger"
      typeModal="transaction"
      Icon="mdi-close-circle"
      :onHandlerNo="cancelFailed"
      :onHandlerYes="buttonOkeFailed"/>
      
      <div align="center">
        <canvas id="photoTaken" ref="canvas" height="0"></canvas>
        <div>
          <v-btn style="font-size: 14px;text-transform: capitalize;font-weight: 500;color: #2CD196;letter-spacing: 0.04em;"
            @click="takePhoto"
            small
            dark
            text>
            {{$t('RepeatPhoto')}}
          </v-btn>
        </div>
      </div>
    
      <v-container v-if="isPhotoTaken && isCameraOpen" class="pt-0">
        <v-row>
          <v-col cols="12" class="py-0">
            <label class="black--text font-weight-bold">{{$t('IdentityNumber')}}</label>
            <v-text-field
              v-model="identityNumber" 
              type="number"
              :label="$t('IdentityNumber')"
              :rules="[rules.required]"
              outlined
              dense
              ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0 mt-n3">
            <label class="black--text font-weight-bold pt-1">{{$t('Address')}}</label>
              <v-textarea
                v-model="address" 
                outlined
                height="80"
                name="input-7-4"
                :placeholder="$t('Address')"
                :rules="[rules.required]"
              ></v-textarea>
          </v-col>
        </v-row>
        <v-container v-if="isPhotoTaken && isCameraOpen" class="pt-0 camera-shoot" style="width:100%" align="center">
          <v-row justify="center">
            <v-col cols="8">
              <v-btn
                class="button-information"
                @click="Confirmation()"
                color="primary"
                normal
                dark
                width="100%"
                rounded>{{$t('Confirmation')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
  </div>
</div>
</template>
<script>
import NotificationPopup from "@/components/NotificationPopup.vue";

  export default {
    name: 'CameraOnIdentityCardKyc',
    components: { 
    NotificationPopup,
    // WebCam,
    },
    data() {
      return {
        identityNumber:"",
        address : "",
        isCameraOpen: true,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        link: '#',
        listCamera: [],
        frontCam: true,
        backCam: false,
        notifFailed:false,
        errorfrontCam: "",
        errorbackCam: "",
        dataResponse:"",
        id_card : this.$store.state.cdn.photo_identiti_card,
        rules: {
          required: (value) => !!value || "Required.",
        },
      }
    },
    mounted(){
      if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
        console.log("Let's get this party started")
      }
      this.isCameraOpen = true;
      this.frontCamera();

    },
    methods:{
      changeCamera() {
        if(this.backCam == true){
          this.backCam = false;
          this.frontCamera()
        } else {
          this.frontCam = false
          this.backCamera()
        }
      },
      cancelFailed(){
        this.notifFailed=false;
      },
      backCamera(){
        this.isLoading = true;
        const constraints = (window.constraints = {
          audio: false,
          video:{facingMode:{exact:"environment"}}
        });
        
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            console.log(error)
            this.frontCamera()
          });
          this.backCam = true;
      },
      frontCamera(){
        this.isLoading = true;
          const constraints = (window.constraints = {
            audio: false,
            video : {facingMode:"user"}
          });

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error)
            alert("May the browser didn't support or there is some errors.");
          });
          this.frontCam = true;
      },
      b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 100;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);
        
            byteArrays.push(byteArray);
        }

       var blob = new Blob(byteArrays, {type: contentType});
        return blob;
      },
      Confirmation(){
        var canvas = document.getElementById("photoTaken");
        var img    = canvas.toDataURL("image/jpeg");
        var block = img.split(";");

        // Get the content type of the image
        var contentType = block[0].split(":")[1];// In this case "image/gif"
        // get the real base64 content of the file
        var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

        // Convert it to a blob to upload
        var blob = this.b64toBlob(realData, contentType);

        // console.log('Encoded Binary File String:', blob);
        
        if (this.identityNumber == "" && this.address ==""){
          this.notifFailed = true
        }

        let payload = {
          file: blob,
          filename: this.$store.state.auth.userData.user_name + "_id_card.jpg",
        }

        this.$store
        .dispatch("cdn/uploadPhotoIdentity", payload)
        .then((response) => {
          this.dataResponse =  response.photo
        }).then(()=>{
          if (this.dataResponse == ""){
            this.notifFailed = true
          } else{
            console.log(this.dataResponse)
            let payloadKyc = {
                card_id: this.identityNumber,
                address: this.address,
                kycp_photo_id_card: this.dataResponse
            }
            
            this.$store
            .dispatch("passenger/premiumKyc", payloadKyc)
            .then((resp) => {
              if (resp.status == true) {
                this.$router.push({
                  name: 'SelfieIdentityCardPremium',
                })
              }
            })
            .catch((err) => {
              this.notifFailed = true
              console.log("eror", err);
            });
          }
        })
        .catch((err) => {
          this.notifFailed = true
          console.log(err);
        });
        
      },
      buttonOkeFailed(){
        this.notifFailed = false
      },
      stopCameraStream() {
        let tracks = this.$refs.camera.srcObject.getTracks();

        tracks.forEach(track => {
          track.stop();
        });
      },
      takePhoto() {
        if(!this.isPhotoTaken) {
          this.isShotPhoto = true;

          const FLASH_TIMEOUT = 50;

          setTimeout(() => {
            this.isShotPhoto = false;
          }, FLASH_TIMEOUT);
        }
        
        this.isPhotoTaken = !this.isPhotoTaken;
        const context = this.$refs.canvas.getContext('2d');
        context.canvas.width = window.innerWidth;
        context.canvas.height = 281.25;
        context.drawImage(this.$refs.camera, 0, 0,window.innerWidth, 281.25);
      },
    },
  }
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 601px) {
  .camera-stream {
    width: 100%;
    margin:auto;
    background-position: center;
  }

  .grey-background {
    position: flex;
    display: block;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background-color: #646464;
    color: #ffffff;
  }

  .white-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background: #ffffff;
  }

  .camera-shoot {
    position: fixed;
    bottom: 0;
    left:0;
    width: auto;
    text-align: center;
    margin: 1rem 0; 
    cursor: pointer;
  }

 .button-information{
    width: 100%;
    color:#4BB14E;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .camera-stream {
    width: 100%;
    max-height: 80%;
    margin:auto;
    background-position: center;
  }

  .grey-background {
    position: flex;
    display: block;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background-color: #646464;
    color: #ffffff;
  }

  .white-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background: #ffffff;
  }

  .camera-shoot {
    position: fixed;
    bottom: 0;
    left:0;
    width: auto;
    text-align: center;
    margin: 1rem 0; 
    cursor: pointer;
  }
  
  .camera-img-capture{
    margin-top: -30px;  

  }

 .button-information{
    width: 100%;
    color:#4BB14E;

  }
}
</style>